<template>
  <main class="basicinfo">
    升级会员页面
  </main>
</template>

<script>
export default {
  name: 'Member'
}
</script>

<style>

</style>